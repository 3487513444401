<template>
    <div class="coupon">
        <van-tabs v-model="qrystatus" 
                @change="changeTab" 
                color="#E69F12"
                title-active-color="#E69F12"
                sticky
                swipeable>
            <van-tab title="未使用" name="1" :badge="unuse">
                <div class="treat_main_list" v-if="list1.length > 0">
                    <van-list
                        v-model="loading1"
                        :finished="finished1"
                        @load="onLoad"
                        :offset="30"
                        :immediate-check="false"
                        
                        finished-text="加载完成">
                        <div class="card" v-for="item in list1" >
                            <div class="card-left">
                                <div class="top"> 
                                <div class="money">￥{{  parseFloat(item.money) }}</div>
                                <div class="tip">满{{item.minmoney}}元可用</div>  
                                </div>
                                  <strong class="card-time">{{ item.startdate }}至{{ item.enddate }}</strong>
                            </div>
                            <div class="card-right">
                              
                                <div class="top"> 
                                    <div class="couponsn">领取时间：{{item.createtime}}</div>
                                    <div class="couponsn">优惠券号：{{item.couponsn}}</div>
                                 
                                </div>
                                    <p class="card-info">{{item.couponname }}</p>
                                    <p style="font-size: 12px;color: #fff;">{{ item.remark }}</p>
                            </div>
                            
                            <div class="ribbon">未使用</div>
                        </div>
                    </van-list>
                </div>
                <div class="nodata" v-else>暂无优惠券数据</div>
            </van-tab>
            <van-tab title="已使用" name="2" :badge="used">
                <div class="treat_main_list" v-if="list2.length > 0">
                    <van-list
                        v-model="loading2"
                        :finished="finished2"
                        @load="onLoad"
                        :offset="30"
                        :immediate-check="false"
                        
                        finished-text="加载完成">
                        <div class="card" v-for="item in list2" >
                            <div class="card-left">
                                <div class="top"> 
                                <div class="money">￥{{  parseFloat(item.money) }}</div>
                                <div class="tip">满{{item.minmoney}}元可用</div>  
                                </div>
                                  <strong class="card-time">{{ item.startdate }}至{{ item.enddate }}</strong>
                            </div>
                            <div class="card-right">
                              
                              <div class="top"> 
                                  <div class="couponsn">领取时间：{{item.createtime}}</div>
                                  <div class="couponsn">优惠券号：{{item.couponsn}}</div>
                               
                              </div>
                                  <p class="card-info">{{item.couponname }}</p>
                                  <p style="font-size: 12px;color: #fff;">{{ item.remark }}</p>
                          </div>
                            
                            <div class="ribbon">已使用</div>
                        </div>
                    </van-list>
                </div>
                <div class="nodata" v-else>暂无优惠券数据</div>
            </van-tab>
            <van-tab title="已过期" name="3" :badge="expire">
                <div class="treat_main_list"  v-if="list3.length > 0">
                    <van-list
                        v-model="loading3"
                        :finished="finished3"
                        @load="onLoad"
                        :offset="30"
                        :immediate-check="false"
                        
                        finished-text="加载完成">
                        <div class="carduse" v-for="item in list3">
                            <div class="card-left">
                                <div class="top"> 
                                <div class="money">￥{{ parseFloat(item.money) }}</div>
                                <div class="tip">满{{item.minmoney}}元可用</div>  
                                </div>
                                  <strong class="card-time">{{ item.startdate }}至{{ item.enddate }}</strong>
                            </div>
                            <div class="card-right">
                              
                              <div class="top"> 
                                  <div class="couponsn">领取时间：{{item.createtime}}</div>
                                  <div class="couponsn">优惠券号：{{item.couponsn}}</div>
                               
                              </div>
                                  <p class="card-info">{{item.couponname }}</p>
                                  <p style="font-size: 12px;color: #fff;">{{ item.remark }}</p>
                          </div>
                            
                            <div class="ribbon">已过期</div>
                        </div>
                    </van-list>
                </div>
                <div class="nodata" v-else>暂无优惠券数据</div>
            </van-tab>
      
        </van-tabs>
    </div>
</template>
<script>
import {wxtriagecouponnum,wxtriagecouponqry} from '@/api/medical'
import { mapGetters } from 'vuex'
export default{
    computed: {
            ...mapGetters([
                'username','dispname','clinicid'
            ]),
        },
    data(){
        return{
            qrystatus:'1',
            list2: [],
            // 无限加载
            loading2: false,
            finished2: false,
            list3: [],
            // 无限加载
            loading3: false,
            finished3: false,
            list1: [],
            // 无限加载
            loading1: false,
            finished1: false,
            page:0,
            unuse: 0,
            used: 0,
            expire:0,
        }
    },
    created(){
        this.$store.dispatch('hideOrShowNav', false)
        this.onLoad()
        this.getcouponnum()
        
    },
    methods:{
        changeTab(val){

            this.page = 0;
            this.list2 =  [];
            // 无限加载
            this.loading2 = false;
            this.finished2 = false;
            this.list3 = [];
            this.loading3 = false;
            this.finished3 = false;
            this.list1 = [];
            this.loading1 = false;
            this.finished1 = false;
            this.onLoad()

        },
        onLoad(){
            this.loading = true
            this.page += 1;
            if(this.qrystatus === '1' && this.finished1) {
                return
            }
            if(this.qrystatus === '2' && this.finished2) {
                return
            }
            if(this.qrystatus === '3' && this.finished3) {
                return
            }
            let data = {
                username:this.username,
                qrypage:this.page,
                userid:this.$route.query.userid,
                customerid:this.$route.query.customerid,
                qrytype:this.qrystatus
            }
            wxtriagecouponqry(data).then(response =>{
            
                 if(this.qrystatus == 1){
                    this.loading1 = false
                    if(response.response_body.count > 0){

                        this.list1 =[...this.list1, ...response.response_body.list] || []
                  
                        if(this.page >= response.response_body.pages){
                            
                            this.finished1 = true
                        }
                    }else{
                        this.list1 = []
                        this.finished1 = true
                    }

                 }else if(this.qrystatus == 2){
                    this.loading2 = false
                    if(response.response_body.count > 0){

                        this.list2 =[...this.list2, ...response.response_body.list] || []
   
                        if(this.page >= response.response_body.pages){
                            
                            this.finished2 = true
                        }
                    }else{
                        this.finished2 = true
                        this.list2 = []
                    }
                 }else if (this.qrystatus == 3){
                    this.loading3 = false
                    if(response.response_body.count){

                        this.list3 =[...this.list3, ...response.response_body.list] || []
       
                        if(this.list3.length >= response.response_body.count){
                            
                            this.finished3 = true
                        }
                    }else{
                        this.list3 = []
                        this.finished3 = true
                    }
                 }
   
            })
            
        },
        getcouponnum(){
            let data = {
                username:this.username,
                userid:this.$route.query.userid,
                customerid:this.$route.query.customerid,
            }
            wxtriagecouponnum(data).then(response =>{

                this.unuse = response.response_body.nousenum
                this.used = response.response_body.usenum
                this.expire = response.response_body.expriynum
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.coupon{

    .card{
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 10px;
    //   width: 16%;
      padding: 10px 0;
      background: #fff;
      border-radius: 8px;
      -webkit-mask-image: radial-gradient(circle at 108px 4px, transparent 4px, red 4.5px),radial-gradient(closest-side circle at 50%, red 99%, transparent 100%);
      -webkit-mask-size: 100%, 2px 4px;
      -webkit-mask-repeat: repeat, repeat-y;
      -webkit-mask-position: 0 -4px, 107px;
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      background: linear-gradient(45deg, orange, #fa6400);
    }
    .carduse{
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 10px;
      color:#b4b4b4!important;
    //   width: 16%;
      padding: 10px 0;
      background: #fff;
      border-radius: 8px;
      -webkit-mask-image: radial-gradient(circle at 88px 4px, transparent 4px, red 4.5px),radial-gradient(closest-side circle at 50%, red 99%, transparent 100%);
      -webkit-mask-size: 100%, 2px 4px;
      -webkit-mask-repeat: repeat, repeat-y;
      -webkit-mask-position: 0 -4px, 87px;
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      background: linear-gradient(45deg, #e5e2e2, #fafafa);
      .card-left{
        color:unset;
      }
      .card-info{
        color:unset;
      }
      .card-time{
        color:unset;
      }
    }
    
    .card-left{
      width: 108px;
      text-align: center;
      font-size: 18px;
      color: #fff;
      padding: 0px 5px;
      .money{
        font-size: 20px;
      }
      .tip{
        font-size: 12px;
      }
    }
    .card-right{
      padding: 10px 12px;
      display: flex;
      flex: 1;
      flex-direction: column;
      
    }
    .top{
          margin-bottom: 5px;
          padding-bottom: 8px;
            border-bottom: 1px solid #ccc;
      }
      .couponsn{
        font-size: 11px;
        line-height: 14px;
        color:#fff;
        white-space: nowrap;
      }
    .card-info{
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      color:#fff;
    }
    .card-time{
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
      color: #fff;
      margin-top: 4px;
    }
    .ribbon {
        width: 80px;
        height: 20px;
        background: #ccc;
        position: absolute;
        right: -20px;
        top: 10px;
        transform: rotateZ(45deg);
        text-align: center;
        color: #fff;
        font-size: 12px;
    }
    .treat_main_list{
        background-color: #fff;
    }
    .nodata{
        text-align: center;
        margin: 50px 20px ;
    }
}
</style>